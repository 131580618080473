import { IndexView } from './view';
import { 
	BrowserRouter, 
	NavigateFunction, 
	Route, Routes, 
	useLocation, 
	useNavigate 
} from 'react-router-dom';
import { SsoAuth } from './components/ssoauth/ssoauth';
import { ContextStore, useStoreCtx } from './store';
import { SubscriptionOffer } from './components/subscription/subscription';
import { KionSuccess } from './components/subscription/success/success';
import { KionError } from './components/subscription/error/error';
import { ReactNode, useEffect } from 'react';

type RouteGuardCallback = (navigate: NavigateFunction) => void;

const useRouteGuard = (callback: RouteGuardCallback) => {
	const httpService = useStoreCtx().appStore;
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		httpService.previousPage = window.location.href;
		document.querySelector(".main.scrollable")?.scrollTo(0,0);

		const fromQuery = new URLSearchParams(location.state?.from?.search);
		const toQuery = new URLSearchParams(location.search);

		// if (!location.search) {
		// 	window.sessionStorage.removeItem("analyticsQuery");
		// 	return callback(navigate);
		// }

		const queryToObject = (query: URLSearchParams): Record<string, string> => {
			const obj: Record<string, string> = {};
			query.forEach((value, key) => {
				obj[key] = value;
			});
			return obj;
		}

		if (fromQuery.toString()) {
			const fromQueryObject = queryToObject(fromQuery);
			console.log(JSON.stringify(fromQueryObject));
			window.sessionStorage.setItem("analyticsQuery", JSON.stringify(fromQueryObject));
		}
  
		if (toQuery.toString()) {
			const toQueryObject = queryToObject(toQuery);
			console.log(JSON.stringify(toQueryObject));
			window.sessionStorage.setItem("analyticsQuery", JSON.stringify(toQueryObject));
		}

		callback(navigate)
	}, [location, navigate, callback]);
};

type ProtectedRouteProps = {
	guard: RouteGuardCallback;
	children: ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ guard, children }) => {
	useRouteGuard(guard);
	return <>{children}</>;
};

function App() {
  const store = useStoreCtx()
  const httpService = useStoreCtx().appStore;

  return (
    <ContextStore.Provider value={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
			  <ProtectedRoute guard={() => {
				  console.log('Вызов useRouteGuard() при инициализации')
			  }}><IndexView/></ProtectedRoute>
		  }>
            <Route path="ssoauth" element={<SsoAuth/>}/>
          </Route>

          <Route path="subscriptions" element={
			  <ProtectedRoute guard={(navigate) => {
				if (!httpService.promoCode) navigate({pathname: '/', search: new URLSearchParams(httpService.getAnalyticsQuery()).toString()});
			  }}><SubscriptionOffer/></ProtectedRoute>
		  }/>
		  <Route path="success" element={
			  <ProtectedRoute guard={(navigate) => {
				if (!httpService.promoCode) navigate({pathname: '/', search: new URLSearchParams(httpService.getAnalyticsQuery()).toString()});
			  }}><KionSuccess/></ProtectedRoute>
		  }/>
		  <Route path="error" element={
			  <ProtectedRoute guard={(navigate) => {
				if (!httpService.promoCode) navigate({pathname: '/', search: new URLSearchParams(httpService.getAnalyticsQuery()).toString()});
			  }}><KionError/></ProtectedRoute>
		  }/>
        </Routes>
      </BrowserRouter>
      <div id="bank-payment-widget" />
    </ContextStore.Provider>
  );
}

export default App;
